<template>
  <div class="text-center">
    <div class="row no-gutters mb-2">
      <div class="col-sm-6">
        <h3 class="text-left ml-4">Vessel List</h3>
      </div>
      <div class="col-sm-6 text-right form-inline justify-content-end">
        <select class="form-control form-control-sm w-auto ml-3" v-model="params.active">
          <option value="">SELECT STATUS</option>
          <option :value="null">ALL</option>
          <option :value="1" class="bg-light">ACTIVE</option>
          <option :value="0" class="bg-light">INACTIVE</option>
        </select>
        <button v-if="isAllowed('vessels.create')" class="e-btn e-btn-green e-text-white ml-3 pr-3"
                @click="$router.push({name:'VesselAdd'})">
          <font-awesome-icon icon="plus-square" style="width: 25px"/>
          <small class="font-weight-bold">
            NEW VESSEL
          </small>
        </button>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col pb-2">
        <nav aria-label="...">
          <ul class="pagination text-smallest mb-0 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setVesselsToTable(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item" :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setVesselsToTable(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setVesselsToTable(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-3 pb-2">
        <input type="text" name="vesselSearch" class="form-control form-control-sm" v-model="params.search" placeholder="Search Vessel">
      </div>
    </div>
    <div class="row no-gutters" style="height: 80vh; overflow-y: auto">
      <div class="col-sm-12">
        <table class="small e-tbl">
          <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
          <tr>
            <th class="align-middle" style="width: 1em" scope="col">#</th>
            <th class="align-middle" scope="col">SHIP NAME</th>
            <th class="align-middle" scope="col">FLAG</th>
            <th class="align-middle" scope="col">SHIP TYPE</th>
            <th class="align-middle" scope="col">MANAGEMENT</th>
            <th class="align-middle" scope="col">FLEET</th>
            <th class="align-middle" style="width: 5em" scope="col">STATUS</th>
            <th style="width: 70px" scope="col">ACTION</th>
          </tr>
          </thead>
          <tbody>

          <tr v-if="loading">
            <td colspan="100%">
              <app-loading></app-loading>
            </td>
          </tr>

          <template v-else>
            <tr v-for="(vessel, index) in vesselList" :key="vessel.id">
              <td class="align-middle">{{ index + pagination.from }}</td>
              <td class="align-middle text-uppercase">{{ vessel.name }}</td>
              <td class="align-middle">{{ vessel.flag ? vessel.flag.name : '' }}</td>
              <td class="align-middle">{{ vessel.vessel_type ? vessel.vessel_type.name : '' }}</td>
              <td class="align-middle">{{ vessel.management_company ? vessel.management_company.name : '' }}</td>
              <td class="align-middle">{{ vessel.fleet ? vessel.fleet.name : '' }}</td>
              <td class="align-middle">
                {{ vessel.active == 1 ? 'ACTIVE' : 'INACTIVE' }}
              </td>
              <td class="align-middle py-1">
                <div class="dropdown dropright" v-if="isElite() || isAllowed('vessels.delete') || isOwnVessel(vessel.id)">
                  <button
                    id="actionDropdown"
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="mr-2 btn btn-sm btn-secondary force-white-all"
                    data-toggle="dropdown"
                  >
                    <font-awesome-icon icon="cogs"/>
                  </button>
                  <div aria-labelledby="actionDropdown" class="dropdown-menu py-0">
                    <button class="dropdown-item text-secondary font-weight-bolder"
                            @click="vesselOverview(vessel.id)">
                      <font-awesome-icon icon="eye"/>
                      View
                    </button>
                    <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                            @click="deleteSelected(vessel.id)"
                    >
                      <font-awesome-icon icon="trash" class="e-text-red"/>
                      Delete
                    </button>
                  </div>
                </div>
                <button class="e-btn e-btn-blue e-btn-sm e-text-white"
                        @click="vesselOverview(vessel.id)" v-else>
                  <font-awesome-icon icon="eye" />
                  View
                </button>
              </td>
            </tr>
          </template>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {vueAppMixin} from "@/mixins/vueAppMixin";
import AppLoading from "@/components/elements/AppLoading";

import _ from 'lodash';
import {permissionMixin} from "@/mixins/permissionMixins";
import {AlertService} from "@/services/AlertService";
import {PaginationMixin} from "@/mixins/PaginationMixin";

export default {

  mixins: [vueAppMixin,permissionMixin,PaginationMixin],
  components: {AppLoading},

  name: 'VesselIndex',
  data() {
    return {
      params: {
        active: 1,
        page:1,
        search:''
      },
      loading: false,
    }
  },
  methods: {
    ...mapActions([
      'getVessels',
      'deleteVessel'
    ]),
    // todo
    async vesselOverview(vesselId) {
      // await this.$router.push({ name: 'VesselShow', params: { id: vesselId } }).catch(() => {})
      await this.$router.push({
        name: "VesselParticular",
        params: {id: vesselId},
      });

    },
    async deleteSelected(vesselId){
      if(await AlertService.confirmDeleteAlert('Delete VESSEL Record', 'Are you sure you want to delete this Vessel Record?', 'Delete')){
        swal.fire({
          title:'ENTER YOUR PASSWORD',
          input: 'password',
          inputAttributes: {
            autocapitalize: 'off'
          },
          confirmButtonText:'PROCEED DELETION',
          cancelButtonText: 'CANCEL',
          showCancelButton:true,
          allowOutsideClick:false,
          allowEscapeKey:false,
          allowEnterKey:false,
          confirmButtonColor:'#dc3545',
          showLoaderOnConfirm:true,
          preConfirm:async (password)=> {
            let response = await this.deleteVessel({vesselId, password})


            if (typeof response === 'boolean' && response) {
              return response;
            } else {
              if (response.hasOwnProperty('message') && !response.hasOwnProperty('errors')) {
                swal.showValidationMessage(response.message);
              }
              else{
                swal.showValidationMessage(response.errors?.password.join(','));
              }
            }
          }
        })
        .then(async (result)=>{
          if(result.isConfirmed){
            await this.successAlert('VESSEL RECORD DELETED SUCCESSFULLY','DELETE VESSEL RECORD');
            await this.setVesselsToTable();
          }
        });
      }
    },
    async setVesselsToTable(page=1) {
      this.loading = true;
      this.params.page=page
      await this.getVessels(this.params)
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters(['pagination',
    'vesselList',
      'isLoading',

    ])
  },
  async created() {
    this.setVessels = _.debounce(this.setVesselsToTable, 750)
    await this.setVesselsToTable();
  },
  watch: {
    'params.active'() {
      this.setVessels()
    },
    'params.search'(){
      this.setVessels();
    }
  },

  destroyed() {
    this.params.active = 1;
  }

}
</script>

<style scoped lang="scss">

</style>
